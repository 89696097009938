fieldset {
  background: #ffffff;
  border: 1px solid #e6e8e8;
  border-radius: 5px;
  margin: 20px 0 20px 0;
  padding: 25px;
  position: relative;

  legend {
    background: inherit;
    font-family: "Lato", sans-serif;
    color: #58748B;
    font-size: 15px;
    left: 10px;
    padding: 0 10px;
    position: absolute;
    top: -12px;
    font-weight: 400;
    width: auto !important;
    border: none !important;

  }
}
