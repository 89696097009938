
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  ::v-deep i {
    padding: 0 3px;
  }
}
